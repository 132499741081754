import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["freightType"]
  static outlets = ["nested-form"]

  connect() {
    const wrappers = document.querySelectorAll(this.nestedFormOutlet.wrapperSelectorValue)
    if(wrappers.length == 2) {
      this.freightTypeTarget.value = "collection"
    }
  }
}
