// Use postcode "ID1 1QD" when testing
// See more at: https://ideal-postcodes.co.uk/documentation/testing
import * as env from 'env';

document.addEventListener("turbo:load", function() {

  setupAutocompleteFor(
    'order_jobsite_attributes_address_attributes_postcode',
    {
      postcode: "#order_jobsite_attributes_address_attributes_postcode",
      line_1: "#order_jobsite_attributes_address_attributes_line_1",
      line_2: "#order_jobsite_attributes_address_attributes_line_2",
      line_3: "#order_jobsite_attributes_address_attributes_line_3",
      post_town: "#order_jobsite_attributes_address_attributes_post_town",
      district: "#order_jobsite_attributes_address_attributes_district",
      county: "#order_jobsite_attributes_address_attributes_county",
      latitude: "#order_jobsite_attributes_address_attributes_latitude",
      longitude: "#order_jobsite_attributes_address_attributes_longitude",
    }
  )

  setupAutocompleteForBillingAddress();

  setupAutocompleteFor(
    'customer_company_trading_address_attributes_postcode',
    {
      postcode: "#customer_company_trading_address_attributes_postcode",
      line_1: "#customer_company_trading_address_attributes_line_1",
      line_2: "#customer_company_trading_address_attributes_line_2",
      line_3: "#customer_company_trading_address_attributes_line_3",
      post_town: "#customer_company_trading_address_attributes_post_town",
      district: "#customer_company_trading_address_attributes_district",
      county: "#customer_company_trading_address_attributes_county",
      latitude: "#customer_company_trading_address_attributes_latitude",
      longitude: "#customer_company_trading_address_attributes_longitude",
    }
  )
  if (document.querySelector('.billing_address > a') != undefined){
    document.querySelector('.billing_address > a').addEventListener('click', function(){
      setTimeout(setupAutocompleteForBillingAddress, 500);
      // not the best practice but couldn't find a way to wait for the input to be rendered as it depends on how ctive admin handles that
    })
  }
});

function setupAutocompleteForBillingAddress(){
  setupAutocompleteFor(
    'order_billing_address_attributes_postcode',
    {
      postcode: "#order_billing_address_attributes_postcode",
      line_1: "#order_billing_address_attributes_line_1",
      line_2: "#order_billing_address_attributes_line_2",
      line_3: "#order_billing_address_attributes_line_3",
      post_town: "#order_billing_address_attributes_post_town",
      district: "#order_billing_address_attributes_district",
      county: "#order_billing_address_attributes_county",
      latitude: "#order_billing_address_attributes_latitude",
      longitude: "#order_billing_address_attributes_longitude",
    }
  )
}

function setupAutocompleteFor(inputField, outputFields){
  if (document.getElementById(inputField) == undefined){return;}

  var controller = new IdealPostcodes.Autocomplete.Controller({
    api_key: env.IDEAL_POSTCODES_API_KEY,
    inputField: '#' + inputField, outputFields: outputFields
  });

  var postcodeInput = document.getElementById(inputField);
    if(postcodeInput.value) {
      postcodeInput.addEventListener(
        'focus',
        function() { controller.interface.onInput()},
        { once: true }
      );
    }
}
